import React from 'react';

const Checkbox = ({ type = 'checkbox', tagid, checked, name, onChange }) => {
  return (
    <>
        <input type={type} name={tagid} id={tagid} defaultChecked={checked} tagid={tagid} onChange={onChange} />
        <label htmlFor={tagid}>{name}</label>
    </>
  )
}
export default Checkbox;