import * as React from 'react';

const Tag = props => {
  return (
    <div className="tag" style={{color: props.color}}>
      {props.text}
    </div>
  )
}

export default Tag;