import React, { useEffect } from "react";
import { useQuery} from 'graphql-hooks';
import { Image } from 'react-datocms';
import { Link } from 'react-router-dom';

import "../styles/index.sass";

const getVariables = (searchQuery) => {

  // Parse URL parameters to extract tags --> Can probably use qs as well
  var url_string = window.location.href; 
  var url = new URL(url_string);
  var rawTags = url.searchParams.get("tags");
  var tags = (rawTags !== null) ? rawTags.split`,`.map(x => +x) : [] // Convert strings to ints

  return { 
    variables: {
      tags: tags,
      search: searchQuery
    }
  };
}

const Recipes = props => {

  const searchQuery = props.query ? props.query : "";
  const { loading, error, data, refetch } = useQuery(QUERY, getVariables(searchQuery));

  useEffect(() => {
    refetch(getVariables(searchQuery));
  },
    [ refetch, searchQuery ]
  );

  if (loading) return <h2>Loading...</h2>;
  if (error) return <h2>Something Bad Happened. Contact Harry.</h2>;
  if (!data.allRecipes.length) return <h2>Geen recepten gevonden. Probeer de zoekopdracht en/of het filter eens aan te passen.</h2>;

  return (
    <React.Fragment>
      <h2>{data.allRecipes.length} recepten gevonden</h2>
      <ul className="showcase">
        {data &&
          data.allRecipes.map(recipe => (
            <li key={recipe.id} className="showcase__item">
              <div className="card">
                <Link to={`/recepten/${recipe.slug}`} className="card__image">
                  <Image data={recipe.coverImage.responsiveImage} />
                </Link>
                <figcaption className="card__caption">
                  <h6 className="card__title">
                    <Link to={`/recepten/${recipe.slug}`}>{recipe.title}</Link>
                  </h6>
                  <div className="card__description">
                    <p>{recipe.excerpt}</p>
                  </div>
                </figcaption>
              </div>
            </li>
          )
        )}
      </ul>
    </React.Fragment>
  );
  
};

const QUERY = `query recipes($tags: [ItemId], $search: String!) {
  meta: _allRecipesMeta {
    count
  }
  allRecipes(
    first: 100,
    orderBy: _createdAt_ASC,
    filter: { 
      tags: { allIn: $tags },
      OR: [
        { title: { matches: { pattern: $search, caseSensitive: false } } },
        { excerpt: { matches: { pattern: $search, caseSensitive: false } } },
        { amount: { matches: { pattern: $search, caseSensitive: false } } },
        { time: { matches: { pattern: $search, caseSensitive: false } } },
        { additionaltools: { matches: { pattern: $search, caseSensitive: false } } },
        { preparation: { matches: { pattern: $search, caseSensitive: false } } },
        { tips: { matches: { pattern: $search, caseSensitive: false } } }
      ]
    }
  ) {
    id
    coverImage {
      responsiveImage(imgixParams: { fit: crop, w: 600, h: 600, auto: format }) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        base64
      }
    }
    excerpt
    slug
    tags {
      id
      name
    }
    title
  }
}`;

export default Recipes;
