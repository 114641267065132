import React, { useEffect, useState } from "react";
import { useQuery} from 'graphql-hooks';
import { useHistory, useLocation, Link, Route } from "react-router-dom";
import Checkbox from './checkbox.js';

const Navigation = props => {

  const history = useHistory();
  const location = useLocation();

  // Parse URL parameters to extract tags --> Can probably use qs as well
  var url_string = window.location.href; 
  var url = new URL(url_string);
  var rawTags = url.searchParams.get("tags");
  var tags = (rawTags !== null) ? rawTags.split`,`.map(x => +x) : [] // Convert strings to ints
  var activeTags = {};
  tags.forEach(tag => activeTags[tag] = true );

  const [checkedItems, setCheckedItems] = useState(activeTags); // Plain object as state

  const handleChange = (event) => {
    setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
  }

  const { loading, error, data } = useQuery(QUERY);

  useEffect(() => {
    
    if (location.pathname === "/") {
      // Put checked tags in array
      var tags = Object.keys(checkedItems).filter(function(key) {
          return checkedItems[key]
      });
    
      // Set checked tags as url parameter
      if (tags.length) {
        history.push('/?tags=' + tags);
      }
      else {
        history.push('/');
      }
    }
  },
    [checkedItems, history, location.pathname]
  );

  if (loading) return <p className="loadingIndicator">Loading...</p>;
  if (error) return <p>Something Bad Happened. Contact Harry.</p>;
  
  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
            <h6 className="navigation__title">
            <img className="navigation__logo"
                    src={data.home.logo.url}
                    alt={data.home.logo.alt}
            />
            <Link to="/">{data._site.globalSeo.siteName}</Link>
            </h6>
            <div className="navigation__intro"
                dangerouslySetInnerHTML={{
                    __html:
                    data.home.introText
                }}
            />
            <Route path="/recepten/">
                <Link className="navigation__backlink" to="/">&larr; Terug naar overzicht</Link>
            </Route>
            <Route exact path="/">
              <div className="navigation__filter">
                <ul className="navigation__tags">
                  { data.allTags.map(tag => (
                    <li key={tag.id} className="navigation__tag">
                        <Checkbox name={tag.name} tagid={tag.id} checked={checkedItems[tag.id]} onChange={handleChange} />
                    </li>
                  ))}
                </ul>
              </div>
            </Route>
            <div className="navigation__footer">
                <p className="navigation__social">
                    {data.allSocialProfiles.map(profile => (
                        <a key={profile.profileType}
                            href={profile.url}
                            target="blank"
                            className={`social social--${profile.profileType.toLowerCase()}`}
                        >
                        {" "}
                        </a>
                    ))}
                </p>
                <div className="navigation__copyright">
                    {data.home.copyright}
                </div>
            </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const QUERY = `query data {
    _site {
      globalSeo {
        facebookPageUrl
        siteName
        titleSuffix
        twitterAccount
      }
    }
    home {
      logo {
        url
        alt
      }
      seoSettings {
        description
        title
      }
      introText
    }
    allSocialProfiles {
      id
      profileType
      url
    }
    allTags {
      id
      name
    }
  }`;
  
  export default Navigation;