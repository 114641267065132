import React, { useState } from 'react'

const Ingredient = ({ data, multiplier }) => {
    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    }

    const amount = data.amount * multiplier + " " + data.unit;
    const altamount = data.altamount ? data.altamount * multiplier + " " + data.altunit : undefined;

    return (
        <tr key={data.id} className={`ingredient ${checked ? "checked" : ""}`}>
            <td>
                <input type="checkbox"
                       id={data.id} 
                       checked={checked}
                       onChange={handleChange}/>
            </td>
            <td>
                <label htmlFor={data.id}>
                    {amount}
                </label>
            </td>
            <td>
                <label htmlFor={data.id}>
                    {data.name} {altamount && <span> ({altamount})</span>}
                </label>  
            </td>
        </tr>
    )
}

export default Ingredient;
