import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import { GraphQLClient, ClientContext } from 'graphql-hooks';

import "./styles/index.sass";

const apiToken = process.env.REACT_APP_DATO_API_TOKEN;
const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    "Authorization": "Bearer " + apiToken
  }
});

ReactDOM.render(
    <ClientContext.Provider value={client}>
        <App />
    </ClientContext.Provider>,
    document.getElementById("root")
);