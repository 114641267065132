import React, { useState } from "react";
import { useQuery } from 'graphql-hooks';
import Recipes from './recipes';
import Recipe from './recipe';
import Navigation from './navigation';
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { renderMetaTags } from "react-datocms";
import classNames from "classnames";

const App = props => {

  const [menuVisible, setMenuVisible] = useState(false);
  const { loading, error, data } = useQuery(HOMEPAGE_QUERY);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  }

  if (loading) return <p className="loadingIndicator">Loading...</p>;
  if (error) return "Something Bad Happened. Contact Harry.";

  return (
    <div className="container">
      <Helmet>
        {renderMetaTags(data.home.seo.concat(data.site.favicon))}
      </Helmet>
      <Router>
        <div className="mobile-header">
            {data &&
              <>
                <img className="mobile-header__logo"
                    src={data.home.logo.url}
                    alt={data.home.logo.alt}
                />
                <h6 className="mobile-header__title"><Link to="/">{data.site.globalSeo.siteName}</Link></h6>
              </>
            }
            <button className="mobile-header__menu" onClick={() => setMenuVisible(!menuVisible)} />
        </div>
        <aside className={classNames("container__navigation", { "menuVisible": menuVisible })}
              onClick={() => setMenuVisible(false)}>
          <Navigation />
        </aside>
        <main className="container__body">
          <Route exact path="/" render={(props) => (
            <>
              <div className="container__search">
                <input type="search" value={searchQuery} onChange={handleSearch} placeholder="Zoeken..."/>
              </div>
              <Recipes {...props} query={searchQuery} />
            </>
          )} />
          <Route path="/recepten/:slug" component={Recipe} />
        </main>
      </Router>
    </div>
  )

};

const HOMEPAGE_QUERY = `
  {
    site: _site {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
      globalSeo {
        facebookPageUrl
        siteName
      }
    }
    home {
      logo {
        url
        alt
      }
      seo: _seoMetaTags {
        attributes
        content
        tag
      }
    }
  }`;

export default App;