import React, { useState } from "react";
import { useQuery} from 'graphql-hooks';
import Markdown from "react-markdown";
import { Image } from 'react-datocms';
import Ingredient from "./ingredient";
import { Helmet } from "react-helmet";
import { renderMetaTags } from "react-datocms";
import Tag from "./tag";

import "../styles/index.sass";

const Recipe = props => {

    const [multiplier, setMultiplier] = useState(1);

    const variables = {
        variables: {
            slug: props.match.params.slug
        }
    };

    const { loading, error, data } = useQuery(QUERY, variables);

    if (loading) return <h2 className="loadingIndicator">Loading...</h2>;
    if (error) return <h2>Something Bad Happened. Contact Harry.</h2>;

    return (
        <section>
            {data && (
                <article className="sheet">
                    <Helmet>
                        {renderMetaTags(data.recipe.seo)}
                    </Helmet>
                    <div className="sheet__cover">
                        <Image data={data.recipe.coverImage.responsiveImage} />
                    </div>
                    <div className="sheet__inner">
                        <h1 className="sheet__title">{data.recipe.title}</h1>
                        <div className="sheet__tags">
                        <Tag text={data.recipe.recipeType.name}
                            color={data.recipe.recipeType.color.hex} />
                        </div>
                        <p className="sheet__lead">{data.recipe.excerpt}</p>
                        <div className="sheet__body">
                        <h1>Hoeveelheid</h1>
                        <Markdown className="sheet__markdown" source={data.recipe.amount} />
                        <h1>Tijd</h1>
                        <Markdown className="sheet__markdown" source={data.recipe.time} />
                        <h1>Ingrediënten</h1>
                        {data.recipe.ingredients[1].amount &&
                            <div className="sheet__multiplier">
                                <button onClick={() => setMultiplier(0.5)}>0.5x</button>
                                <button onClick={() => setMultiplier(1)}>1x</button>
                                <button onClick={() => setMultiplier(1.5)}>1.5x</button>
                                <button onClick={() => setMultiplier(2)}>2x</button>
                                <button onClick={() => setMultiplier(3)}>3x</button>
                                <button onClick={() => setMultiplier(4)}>4x</button>
                                <button onClick={() => setMultiplier(10)}>10x</button>
                                <p>Voor {multiplier}x de standaardhoeveelheid:</p>
                            </div>
                        }
                        <table id="ingredients">
                            {data.recipe.ingredients.map((ingredient) => (
                                <tbody key={ingredient.id}>
                                    { ingredient._modelApiKey === 'heading' && 
                                        <tr key={ingredient.id}>
                                        <td className="sheet__table-heading" colSpan="3">{ingredient.name}</td>
                                        </tr>
                                    }
                                    { ingredient._modelApiKey === 'ingredient' && 
                                        <Ingredient data={ingredient} multiplier={multiplier} />
                                    }
                                </tbody>
                            ))}
                        </table>
                        <h1>Extra benodigdheden</h1>
                        <Markdown className="sheet__markdown" source={data.recipe.additionaltools} />
                        <h1>Bereiding</h1>
                        <Markdown className="sheet__markdown" source={data.recipe.preparation} />
                        <h1>Tips</h1>
                        <Markdown className="sheet__markdown" source={data.recipe.tips} />
                        </div>
                    </div>
                </article>
            )}
        </section>
    );
  
};

const QUERY = `query singleRecipe($slug: String!) {
    recipe: recipe(filter: { slug: { eq: $slug } }) {
        id
        slug
        title
        coverImage {
          responsiveImage(imgixParams: { fit: crop, w: 1200, h: 627}) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        recipeType {
            name
            id
            color {
                hex
            }
        }
        excerpt
        amount
        time
        ingredients {
            ... on IngredientRecord {
                _modelApiKey
                id
                name
                amount
                unit
                altamount
                altunit
            }
            ... on HeadingRecord {
                _modelApiKey
                id
                name
            }
        }
        additionaltools
        preparation
        tips
        seo: _seoMetaTags {
            attributes
            content
            tag
        }
      }
    }
`;

export default Recipe;
